<script setup lang="ts">
import type { DirectusFiles } from "~/types/directus-collections.d.ts";

const props = defineProps<{
  img: DirectusFiles | string | null | undefined;
  fill?: boolean;
  lazy?: boolean;
  preset?: string;
  auth?: boolean;
}>();

const token = props.auth === true ? await useToken() : undefined;

const image = useImage();

const _imagesrc = computed(() => {
  if (props.img) {
    const id = typeof props.img === "string" ? props.img : props.img.id;

    const src = image(id, undefined, {
      provider: "directus",
      preset: props.preset,
    });

    return props.auth === true ? src + "&access_token=" + token : src;
  }
});
</script>

<template>
  <img :src="_imagesrc" :class="fill ? 'h-full w-full object-cover' : ''" :loading="lazy ? 'lazy' : 'eager'" />
</template>
